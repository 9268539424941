import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import Relation from '../images/services/rationalizaion.jpg'
import Network from '../images/services/networking.jpg'
import Support from '../images/services/support.jpg'
import Security from '../images/services/security.jpg'
import Management from '../images/services/management.jpg'
import Performance from '../images/services/performance.jpg'
import Backup from '../images/services/backup.jpg'
import Virtualization from '../images/services/virtualization.jpg' 
import Consulting from '../images/services/consulting.jpg'
import Cloud from '../images/services/cloud.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import Consulting1 from '../images/services/consulting.jpg'
import Implementation from '../images/services/implementation.jpeg'
import Applications from '../images/services/applications.jpg'
import { StaticImage } from "gatsby-plugin-image"

const SolutionsPage = () => {
    return (
        <Layout pageTitle="Features">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                        <div className="scroll-down-wrap">
                    <span>sentinel@pragicts.com</span>
                        <span>+94 11 70 4598 983</span>
                     
                    </div>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2><span> Features</span></h2>
                                <div className="horizonral-subtitle"><span>Services</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                   
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Features <span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Features</h3>
                    <h2>EXTENSIBILITY <span> SOPE</span></h2>
                    
                </div>
                <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>DATABASES</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom " style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>ORACLE DB INTEGRATION</h3>
                                </div>
                              
                            </div>
                      
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>MSSQL SERVER INTEGRATION</h3>
                                </div>
                               
                            </div>
                         
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>MONGODB INTEGRATION</h3>
                                </div>
                                
                            </div>
                          
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>ELASTICSEARCH INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>IOTDB INTEGRATION</h3>
                                </div>
                                
                            </div>
                          
                            
                        </div>
                    </div>
                   
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-database-icon"></i>
                                    <h3>POSTGRESQL INTEGRATION</h3>
                                </div>
                             
                            </div>
                           
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-database-icon"></i>
                                    <h3>SQL INTEGRATION</h3>
                                </div>
                               
                            </div>
                           
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-database-icon"></i>
                                    <h3>PROXYSQL INTEGRATION</h3>
                                </div>
                               
                            </div>
                           
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-database-icon"></i>
                                    <h3>RAVENDB INTEGRATION</h3>
                                </div>
                               
                            </div>
                       
                            
                        </div>
                    </div>
                   
                 
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-database-icon"></i>
                                    <h3>REDIS INTEGRATION</h3>
                                </div>
                              
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>AEROSPIKE INTEGRATION</h3>
                                </div>
                                
                            </div>
                           
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>CLICKHOUSE INTEGRATION</h3>
                                </div>
                                
                            </div>
                       
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>CLICKHOUSE INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>CONSUAL INTEGRATION (OFFICAL)</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>COUCHBASE INTEGRATION</h3>
                                </div>
                                
                            </div>
                      
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>COUCHDB INTEGRATION</h3>
                                </div>
                                
                            </div>
                     
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>DRUID INTEGRATION</h3>
                                </div>
                                
                            </div>
                  
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>EVENTSTORE INTEGRATION</h3>
                                </div>
                                
                            </div>
                       
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>KDB+ INTEGRATION</h3>
                                </div>
                                
                            </div>
                      
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>MEMCACHED INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                      
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>OPENTSDB INTEGRATION</h3>
                                </div>
                                
                            </div>
                      
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>PGBOUNCER INTEGRATION</h3>
                                </div>
                                
                            </div>
                       
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>PRESTO INTEGRATION</h3>
                                </div>
                                
                            </div>
                     
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>RETHINKDB INTEGRATION</h3>
                                </div>
                                
                            </div>
                     
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>TARANTOOL METRIC LIBRARY</h3>
                                </div>
                                
                            </div>
                     
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>TWEMPROXY</h3>
                                </div>
                                
                            </div>
                      
                            
                        </div>
                    </div>
                    


                </div>
                <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>HARDWARE</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>FORTIGATE INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>DELL HARDWARE OMSA INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>IBM Z HMC INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>BOSCH SENSORTEC BMP/BME INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>NETGEAR CABLE MODEM INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                   
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-hardware-icon"></i>
                                    <h3>NETGEAR ROUTER INTEGRATION</h3>
                                </div>
                             
                            </div>
                   
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-hardware-icon"></i>
                                    <h3>NETWORK UPS TOOLS (NUT) INTEGRATION</h3>
                                </div>
                               
                            </div>
                 
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-hardware-icon"></i>
                                    <h3>NODE/SYSTEM METRICS INTEGRATION (OFFICIAL)</h3>
                                </div>
                               
                            </div>
                      
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-hardware-icon"></i>
                                    <h3>NVIDIA GPU INTEGRATION</h3>
                                </div>
                               
                            </div>
                        
                            
                        </div>
                    </div>
                   
                 
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-hardware-icon"></i>
                                    <h3>WEATHERGOOSE CLIMATE MONITOR INTEGRATION</h3>
                                </div>
                              
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>WINDOWS INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>INTEL® OPTANE™ PERSISTENT MEMORY CONTROLLER</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>APCUPSD INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>BIG-IP INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>COLLINS INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>DISK USAGE INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>IOT EDISON INTEGRATION</h3>
                                </div>
                                
                            </div>
                     
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>INFINIBAND INTEGRATION</h3>
                                </div>
                                
                            </div>
                       
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>IPMI INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>KNXD INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>MODBUS INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>PROSAFE INTEGRATION</h3>
                                </div>
                                
                            </div>
                      
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-hardware-icon"></i>
                                    <h3>WAVEPLUS RADON SENSOR INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                    
                    


                </div>
                <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>MESSAGING SYSTEMS</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>IBM MQ INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>KAFKA INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>MQTT BLACKBOX INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>MQTT2PROMETHEUS</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>RABBITMQ INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                   
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-message-icon"></i>
                                    <h3>RABBITMQ MANAGEMENT PLUGIN INTEGRATION</h3>
                                </div>
                             
                            </div>
                   
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-message-icon"></i>
                                    <h3>ROCKETMQ INTEGRATION</h3>
                                </div>
                               
                            </div>
                 
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-message-icon"></i>
                                    <h3>BEANSTALKD INTEGRATION</h3>
                                </div>
                               
                            </div>
                      
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-message-icon"></i>
                                    <h3>EMQ INTEGRATION</h3>
                                </div>
                               
                            </div>
                        
                            
                        </div>
                    </div>
                   
                 
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-message-icon"></i>
                                    <h3>GEARMAN INTEGRATION</h3>
                                </div>
                              
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>NATS INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>NSQ INTEGRATION</h3>
                                </div>
                                
                            </div>
                        
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>MIRTH CONNECT INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-message-icon"></i>
                                    <h3>SOLACE INTEGRATION</h3>
                                </div>
                                
                            </div>
                         
                            
                        </div>
                    </div>
                  
                    
                    


                </div>
                <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>ISSUE TRACKERS & CONTINUOUS INTEGRATION</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom"  style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-issue-icon"></i>
                                    <h3>JIRA INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-issue-icon"></i>
                                    <h3>BITBUCKET INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-issue-icon"></i>
                                    <h3>BAMBOO INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-issue-icon"></i>
                                    <h3>CONFLUENCE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-issue-icon"></i>
                                    <h3>JENKINS INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>STORAGE</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>TIVOLI STORAGE MANAGER/IBM SPECTRUM PROTECT INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>HADOOP HDFS FSIMAGE INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>HPE CSI INFO METRICS PROVIDER</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>HPE STORAGE ARRAY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>NETAPP E-SERIES INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>CEPH INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>CEPH RADOSGW INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>GLUSTER INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>GPFS INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>LUSTRE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>PURE STORAGE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-storage-icon"></i>
                                    <h3>SCALEIO INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                  
                  
                    
                    

</div>
                <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>HTTP</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>APACHE INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>HAPROXY INTEGRATION (OFFICIAL)</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>NGINX METRIC LIBRARY</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>NGINX VTS INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>PASSENGER INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>SQUID INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>TINYPROXY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>VARNISH INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-http-icon"></i>
                                    <h3>WEBDRIVER INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    
                  
                  
                    
                    

</div>       
                <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>APIS</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>AWS ECS INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>AWS HEALTH INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>AWS SQS INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>AZURE HEALTH INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>CLOUDFLARE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>GITHUB INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>GMAIL INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>MOZILLA OBSERVATORY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>BIGBLUEBUTTON</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>CRYPTOWAT INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>DIGITALOCEAN INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>DOCKER CLOUD INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>DOCKER HUB INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>FASTLY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>INSTACLUSTR INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>OPENWEATHERMAP INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>PAGESPEED INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>RANCHER INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>SPEEDTEST INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-api-icon"></i>
                                    <h3>TANKERKÖNIG API INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    
                  
                  
                    
                    

</div>       
<div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>LOGGING</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-login-icon"></i>
                                    <h3>FLUENTD INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-login-icon"></i>
                                    <h3>GOOGLE'S MTAIL LOG DATA EXTRACTOR</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-login-icon"></i>
                                    <h3>GROK INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                   
                  
                  
                    
                    

</div> 
<div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>OTHER MONITORING SYSTEMS</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>AKAMAI CLOUDMONITOR INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>ALIBABA CLOUDMONITOR INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>AWS CLOUDWATCH INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>AZURE MONITOR INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>CLOUD FOUNDRY FIREHOSE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>COLLECTD INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>GOOGLE STACKDRIVER INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>GRAPHITE INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>HEKA DASHBOARD INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>HEKA INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>HUAWEI CLOUDEYE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>INFLUXDB INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>ITM INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>JAVAMELODY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>JMX INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>MUNIN INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>NAGIOS / NAEMON INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>NEW RELIC INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>NRPE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>OSQUERY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>OTC CLOUDEYE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>PINGDOM INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>PROMITOR (AZURE MONITOR)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>SCOLLECTOR INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>SENSU INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>SITE24X7_INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>SNMP INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>STATSD INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>TENCENTCLOUD MONITOR INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-monitor-icon"></i>
                                    <h3>THOUSANDEYES INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                  
                  
                    
                    

</div>             
<div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>OTHERS</h2>
            
                        </div>
                    </div>
                <div className="custom-inner-holder  mar-bottom" style={{display: "grid",gridTemplateColumns:"500px 500px 500px"}}>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>ACT FIBERNET INTEGRATION</h3>
                                </div>
                              
                            </div>
                       
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>BIND INTEGRATION</h3>
                                </div>
                               
                            </div>
             
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>BIND QUERY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>BITCOIND INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>BLACKBOX INTEGRATION (OFFICIAL)</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>BUNGEECORD INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>BOSH INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>CADVISOR</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>CACHET INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>CCACHE INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>C-LIGHTNING INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>DHCPD LEASES INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>DOVECOT INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>DNSMASQ INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>EBPF INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>MUNIN INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>XEN INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>ETHEREUM CLIENT INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>JFROG ARTIFACTORY INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>HOSTAPD INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>IRCD INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>LINUX HA CLUSTERLABS INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>JMETER PLUGIN</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>JSON INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>KANNEL INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>KEMP LOADBALANCER INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>KIBANA INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>KUBE-STATE-METRICS</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>LOCUST INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>METEOR JS WEB FRAMEWORK INTEGRATION</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-others-icon"></i>
                                    <h3>MINECRAFT INTEGRATION MODULE</h3>
                                </div>
                                
                            </div>
                    
                            
                        </div>
                    </div>
                  
                  
                    
                    

</div>             
            </div>
            <div className="sec-lines"></div>
        </section>
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage
